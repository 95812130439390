import { mergeDeepRight } from 'ramda'

import Button from '@atoms/UIButton/Button'
import Container from '@atoms/UIContainer/Container'
import Error from '@atoms/Error/Error'
import Link from '@atoms/UILink/Link'
import Separator from '@atoms/Separator/Separator'

import { useAuthContext } from '@concepts/Auth/store/context'
import FacebookAuthButton from '@molecules/FacebookAuthButton/FacebookAuthButton'
import GoogleAuthButton from '@molecules/GoogleAuthButton/GoogleAuthButton'
import routes from '@config/routes'
import { ChevronLeftIcon } from '@atoms/Icon/Icon'
import EmailInput from '@atoms/EmailInput/EmailInput'
import PasswordInput from '@atoms/PasswordInput/PasswordInput'
import { useLogin } from '@concepts/Auth/hooks/useLogin'
import EmailAlreadyUsedAlert from '../EmailAlreadyUsedAlert'
import { Box, Text } from '@lib/UIComponents'

const row = {
  marginBottom: 3
}

const styles = {
  loginForm: {
    maxWidth: '500px'
  },
  loginHeader: {
    fontWeight: 'bold',
    fontSize: 1
  },
  row,
  errorRow: row,
  oneColumn: {
    width: '100%'
  },
  forgotPassword: {
    marginTop: 3,
    fontSize: 1,
    a: {
      color: 'blacks.400',
      fontWeight: 'bold',
      textDecoration: 'underline',
      '&:hover': {
        color: '#333'
      }
    }
  },
  column: {
    maxWidth: '500px',
    margin: '0 auto'
  },
  separator: {
    marginBottom: 0
  },
  backButton: {
    mb: 5,
    svg: {
      fontSize: 1,
      marginRight: '6px'
    },
    button: {
      display: 'flex',
      minHeight: 'auto',
      padding: 0
    },
    span: {
      position: 'relative',
      top: '-3px',
      fontSize: 1,
      '&:hover': {
        textDecoration: 'underline'
      }
    }
  }
}

const altStyles = {
  loginHeader: {
    textAlign: 'center',
    display: 'block',
    width: '100%',
    fontSize: [6, 7],
    mb: 7
  },
  row: {
    fontFamily: 'body'
  },
  errorRow: {
    '& span': {
      fontSize: ['14px', '16px']
    }
  },
  separator: {
    mb: '10px'
  },
  forgotPassword: {
    a: {
      fontSize: [1, 2]
    }
  }
}

type Props = {
  onSubmit?: Function
  returnTo?: string
  alt?: boolean
  title?: boolean
  handleBackButton?: Function
  preFilledEmail?: string
}

const LoginForm = ({
  onSubmit,
  returnTo = '/',
  alt,
  title = false,
  handleBackButton,
  preFilledEmail,
  ...props
}: Props) => {
  const [authState] = useAuthContext()

  const {
    handleReferrerCookie,
    userForm,
    handleChange,
    handleBlur,
    errors,
    submitUser
  } = useLogin({
    preFilledEmail,
    onSubmit
  })

  const _styles = alt ? mergeDeepRight(styles, altStyles) : styles

  return (
    <Container sx={_styles.loginForm}>
      <Box sx={_styles.oneColumn}>
        {errors.length > 0 && (
          <Box sx={_styles.errorRow}>
            {errors.map((errorMessage, index) => (
              <Error key={index} message={errorMessage} />
            ))}
          </Box>
        )}

        {handleBackButton && (
          <Box sx={styles.backButton}>
            <Button
              variant="link"
              type="button"
              onClick={() => handleBackButton()}
              sx={{ color: 'grays.600' }}
            >
              <ChevronLeftIcon />
              <Text>Back</Text>
            </Button>
          </Box>
        )}

        {authState.userEmailRegistered && (
          <EmailAlreadyUsedAlert
            publisherName={String(authState.publisherName)}
            signUpDate={String(authState.signUpDate)}
          />
        )}

        {title && (
          <Box sx={_styles.row}>
            <Text as={alt ? 'h2' : 'span'} sx={_styles.loginHeader}>
              Sign Into Your Account
            </Text>
          </Box>
        )}

        <Box as="form" onSubmit={submitUser} {...props}>
          <EmailInput
            id="email"
            placeholder="Email"
            value={userForm.email.value}
            onChange={handleChange('email')}
            onBlur={handleBlur('email')}
            validate
            isValid={userForm.email.isValid}
          />

          <PasswordInput
            id="password"
            placeholder="Password"
            value={userForm.password.value}
            onChange={handleChange('password')}
            sx={{ mt: 3 }}
            validate
            isValid={userForm.password.isValid}
          />

          <Button
            variant="dark"
            size="large"
            type="submit"
            block
            loading={authState.loading}
            sx={{ mt: 5 }}
          >
            Login
          </Button>
        </Box>
      </Box>

      <Box sx={_styles.forgotPassword}>
        <Link
          to={routes.forgotYourPassword()}
          onClick={handleReferrerCookie}
          external
        >
          Forgot Password?
        </Link>
      </Box>

      <Separator text="OR" size="small" variant="light" />
      <FacebookAuthButton signIn returnTo={returnTo} />
      <GoogleAuthButton signIn returnTo={returnTo} />
    </Container>
  )
}

export default LoginForm
