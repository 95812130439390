import { setCookie } from 'src/utils/cookies'

import { FacebookIcon } from '@atoms/Icon/Icon'
import checkoutAnalytics from '@lib/gtm/checkoutAnalytics'

import Redirect from '@utils/redirect'
import Button from '@atoms/UIButton/Button'

const styles = {
  facebookButton: {
    border: '1px solid',
    color: 'facebookBlue',
    borderColor: 'facebookBlue',
    width: '100%',
    position: 'relative',
    svg: {
      fontSize: 2,
      position: 'absolute',
      top: '13px',
      left: '13px'
    }
  }
}

type Props = {
  signIn: boolean
  returnTo?: string
}

const FacebookLoginButton = ({ signIn, returnTo = '/' }: Props) => {
  const handleAuth = () => {
    setCookie('return_to', returnTo)

    if (signIn) {
      checkoutAnalytics.facebookLogin()
    } else {
      checkoutAnalytics.facebookSignUp()
    }

    Redirect({
      pathname: '/auth/facebook',
      external: true
    })
  }

  return (
    <Button
      onClick={handleAuth}
      onBlur={handleAuth}
      type="button"
      size="large"
      variant="outline"
      sx={styles.facebookButton}
      label="Continue With Facebook"
    >
      <FacebookIcon />
      Continue With Facebook
    </Button>
  )
}

export default FacebookLoginButton
