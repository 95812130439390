import { MouseEventHandler } from 'react'

import Error from '@atoms/Error/Error'
import Button from '@atoms/UIButton/Button'
import { Box, Text } from '@lib/UIComponents'

const styles = {
  error: (claimMessage: boolean) => ({
    display: 'flex',
    justifyContent: claimMessage ? 'normal' : 'space-between',
    alignItems: 'center'
  }),
  errorText: (claimMessage: boolean) => ({
    width: claimMessage ? 'auto' : '65%',
    display: 'inline-block',
    span: {
      fontSize: ['xs', 'sm']
    }
  }),
  errorLinks: (claimMessage: boolean) => ({
    display: 'inline-block',
    minWidth: claimMessage ? 'auto' : 155,
    button: {
      fontSize: ['xs', 'sm'],
      padding: 0,
      marginTop: '-5px',
      height: 'fit-content',
      minHeight: 'auto'
    }
  })
}

type ClaimAccountProps = {
  isClaimAccountError: boolean
  error: null | string
  redirectToResetPasswordPage: MouseEventHandler<HTMLButtonElement>
  switchToLogin?: Function
}

const ClaimAccount = ({
  isClaimAccountError,
  error,
  redirectToResetPasswordPage,
  switchToLogin
}: ClaimAccountProps) => (
  <Error sx={styles.error(isClaimAccountError)}>
    <Box sx={styles.errorText(isClaimAccountError)}>
      <Text>{error}</Text>
    </Box>
    <Box sx={styles.errorLinks(isClaimAccountError)}>
      {!isClaimAccountError && (
        <>
          <Button
            type="button"
            variant="link"
            onClick={switchToLogin as () => void}
          >
            Login
          </Button>
          <Text>|</Text>
        </>
      )}
      <Button
        type="button"
        variant="link"
        sx={{ ml: '0.35rem' }}
        onClick={redirectToResetPasswordPage}
      >
        {isClaimAccountError ? 'set a password' : 'Reset Password'}
      </Button>
    </Box>
  </Error>
)

export default ClaimAccount
