import { ComponentProps } from 'react'

import { setCookie } from 'src/utils/cookies'

import Button from '@atoms/UIButton/Button'
import { GoogleIcon } from '@atoms/Icon/Icon'
import checkoutAnalytics from '@lib/gtm/checkoutAnalytics'

import Redirect from '@utils/redirect'

const styles = {
  borderColor: 'blacks.500',
  color: 'blacks.500',
  width: '100%',
  marginTop: 4,
  svg: {
    fontSize: 2,
    position: 'absolute',
    top: '13px',
    left: '13px'
  },
  '&:hover': {
    borderColor: 'black',
    color: 'black'
  }
}

type Props = {
  signIn: boolean
  returnTo?: string
} & ComponentProps<typeof Button>

const GoogleLoginButton = ({ signIn, returnTo = '/', ...props }: Props) => {
  const handleAuth = (): void => {
    setCookie('return_to', returnTo)

    if (signIn) {
      checkoutAnalytics.googleLogin()
    } else {
      checkoutAnalytics.googleSignUp()
    }

    Redirect({
      pathname: '/auth/google_oauth2',
      external: true
    })
  }

  return (
    <Button
      onClick={handleAuth}
      onBlur={handleAuth}
      type="button"
      size="large"
      variant="outline"
      sx={styles}
      label="Continue With Google"
      {...props}
    >
      <GoogleIcon />
      Continue With Google
    </Button>
  )
}

export default GoogleLoginButton
