import { AxiosError, isAxiosError } from 'axios'

import { capitalize } from '@utils/strings'
import { SOMETHING_WENT_WRONG } from '@utils/defaultMessages'

export const processAxiosError = (axiosError: AxiosError | Error): string => {
  if (isAxiosError(axiosError)) {
    const data = axiosError.response?.data

    if (data.message) return capitalize(data.message)

    const error = Object.entries(axiosError.response?.data)[0]
    const attribute = capitalize(error[0])
    const message = capitalize((error[1] as Array<string>)[0])
    return `${attribute}: ${message}`
  }

  return SOMETHING_WENT_WRONG
}
