const digitsRegex = /\d/
const lettersRegex = /[a-zA-Z]/

export type Validations = {
  length: boolean
  content: boolean
}

export const isValid = (password: string): Validations => {
  const value = password.trim()

  return {
    length: value.length >= 8,
    content: lettersRegex.test(value) && digitsRegex.test(value)
  }
}
