import { Tooltip } from 'react-tooltip'

import { HintIcon } from '@atoms/Icon/Icon'
import Card from '@atoms/UICard/Card'
import Link from '@atoms/UILink/Link'

import { formatDateMonthDDYYYY } from '@utils/dates'
import routes from '@config/routes'
import { Box, Flex, Text } from '@lib/UIComponents'

const styles = {
  hint: {
    cursor: 'pointer',
    color: 'grays.250',
    '.__react_component_tooltip': {
      maxWidth: '350px'
    }
  },
  card: {
    padding: 6,
    paddingBottom: 3,
    width: '100%',
    marginBottom: 2
  },
  link: {
    textDecoration: 'underline',
    fontWeight: 'bold'
  },
  text: {
    fontSize: 1
  }
}

type Props = {
  signUpDate: string
  publisherName: string
}

const EmailAlreadyUsedAlert = ({ signUpDate, publisherName }: Props) => {
  return (
    <Card sx={styles.card}>
      <Text sx={styles.text}>
        Looks like you've made a purchase on our network before. This email was
        registered on {publisherName} on {formatDateMonthDDYYYY(signUpDate)}.
        Please login below or{' '}
        <Link sx={styles.link} to={routes.forgotYourPassword()} external>
          Reset Your Password
        </Link>
      </Text>
      <Flex sx={{ justifyContent: 'flex-end' }}>
        <Box
          sx={styles.hint}
          data-for="email-registered-hint"
          data-tooltip-id="email-registered-hint"
        >
          <HintIcon />
        </Box>

        <Tooltip id="email-registered-hint" openOnClick>
          <Box sx={{ fontSize: ['sm', 'md'], maxWidth: '300px' }}>
            StackCommerce powers a network of online shops. When you make a
            purchase on a shop in our network, your login credentials work
            across our entire network of shops.
          </Box>
        </Tooltip>
      </Flex>
    </Card>
  )
}

export default EmailAlreadyUsedAlert
