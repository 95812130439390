import { useState } from 'react'
import { useAuthContext } from '@concepts/Auth/store/context'
import { useCartContext } from '@concepts/Cart/store/context'
import { useReCaptchaContext } from '@concepts/ReCaptcha/store/contextV3'
import { RecaptchaAction } from '@concepts/ReCaptcha/types/actions'
import checkoutAnalytics from '@lib/gtm/checkoutAnalytics'
import { useFlashMessage } from '@concepts/Flash/store/FlashProvider'
import segmentAnalytics from '@lib/segment/analytics'
import { UserForm } from '../types/User'
import { validations } from '../utils/validations'
import { processAxiosError } from '@lib/http/axios/utils'
import { usePublisherContext } from '@concepts/Publisher/store/context'

interface Arguments {
  onSubmit?: Function
  isCheckout?: boolean
}

export type FieldName = 'email' | 'password'

export type SignUpTypes = {
  userForm: UserForm
  createAccount: boolean
  isLoading: boolean
  error: null | string
  isClaimAccountError: boolean
  change: (fieldName: FieldName, value: string) => void
  validate: (fieldName: FieldName) => void
  toggleCreateAccount: () => void
  signUp: () => Promise<void>
}

const CLAIM_ACCOUNT = /To claim your account you need to/

const initialUserForm = {
  email: { value: '', isValid: false },
  password: {
    value: '',
    isValid: { length: false, content: false }
  }
}

const useSignUp = ({
  onSubmit,
  isCheckout = false
}: Arguments): SignUpTypes => {
  const [, auth] = useAuthContext()
  const { cart } = useCartContext()
  const { currentToken } = useReCaptchaContext()
  const { info } = useFlashMessage()
  const { databaseId } = usePublisherContext()

  const [userForm, setUserForm] = useState<UserForm>(initialUserForm)
  const [createAccount, setCreateAccount] = useState(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  const change = (fieldName: FieldName, value: string) => {
    setUserForm((prevUserForm) => ({
      ...prevUserForm,
      [fieldName]: {
        value,
        isValid: validations[fieldName](value)
      }
    }))
  }

  const validate = (fieldName: FieldName) => {
    setUserForm((prevUserForm) => {
      const { value } = prevUserForm[fieldName]

      return {
        ...prevUserForm,
        [fieldName]: {
          value: value.trim(),
          isValid: validations[fieldName](value)
        }
      }
    })
  }

  const toggleCreateAccount = () => {
    if (!createAccount) {
      checkoutAnalytics.clickedCreateAnAccount()
    }

    setCreateAccount(!createAccount)
  }

  const shippingAddressAnalytics = () => {
    checkoutAnalytics.continueToShipping()

    checkoutAnalytics.checkoutStepAddress()
  }

  const paymentAnalytics = () => {
    checkoutAnalytics.continueToPayment()

    checkoutAnalytics.checkoutStepPayment()
  }

  const signUp = async (): Promise<void> => {
    setError(null)
    setIsLoading(true)

    const shouldCreateAccount = createAccount || !isCheckout

    const captchaToken =
      shouldCreateAccount &&
      (await currentToken(RecaptchaAction.RECAPTCHA_SIGN_UP).catch((e: Error) =>
        setError(e.message)
      ))

    if (!captchaToken && shouldCreateAccount) {
      setIsLoading(false)
      return
    }

    try {
      const userFormDTO = {
        email: userForm.email.value,
        password: userForm.password.value,
        source: isCheckout ? 'checkout_signup' : 'page_signup',
        ...captchaToken
      }

      const user = await auth.signUp(userFormDTO, shouldCreateAccount)

      segmentAnalytics.trackSignUp({
        email: user.email,
        userId: user.id,
        signType: 'email',
        publisherId: databaseId as number
      })

      if (shouldCreateAccount) {
        info('Your account was created successfully!')
      }

      if (isCheckout) {
        if (createAccount) {
          checkoutAnalytics.enteredPassword()
        }

        if (cart.shippable) {
          shippingAddressAnalytics()
        } else {
          paymentAnalytics()
        }
      }

      onSubmit?.(user)
    } catch (err) {
      const error = processAxiosError(err as Error)

      if (error.match(CLAIM_ACCOUNT)) {
        setError(`${CLAIM_ACCOUNT.source}`)
      } else {
        setError(error)
      }
    }

    setIsLoading(false)
  }

  return {
    userForm,
    createAccount,
    isLoading,
    error,
    isClaimAccountError: Boolean(error?.match(CLAIM_ACCOUNT)),
    change,
    validate,
    toggleCreateAccount,
    signUp
  }
}

export default useSignUp
