import Cookies from 'js-cookie'
import Router from 'next/router'
import { ChangeEvent, FormEvent, useState } from 'react'

import checkoutAnalytics from '@lib/gtm/checkoutAnalytics'
import segmentAnalytics from '@lib/segment/analytics'
import { useReCaptchaContext } from '@concepts/ReCaptcha/store/contextV3'
import { useAuthContext } from '@concepts/Auth/store/context'
import { RecaptchaAction } from '@concepts/ReCaptcha/types/actions'
import { INVALID_CREDENTIALS } from '@utils/defaultMessages'
import { UserForm } from '../types/User'
import { validations } from '../utils/validations'
import { usePublisherContext } from '@concepts/Publisher/store/context'

export type User = {
  email: string
  id: string
}

export type FieldName = 'email' | 'password'

type ReturnProps = {
  handleReferrerCookie: () => void
  handleChange: (
    name: FieldName
  ) => (event: ChangeEvent<HTMLInputElement>) => void
  handleBlur: (name: FieldName) => () => void
  userForm: UserForm
  errors: Array<string>
  submitUser: (e: FormEvent) => Promise<void>
}

type Props = {
  preFilledEmail?: string
  onSubmit?: Function
}

const initialUserForm = (preFilledEmail: string) => ({
  email: { value: preFilledEmail, isValid: false },
  password: {
    value: '',
    isValid: { length: false, content: false }
  }
})

export const useLogin = ({
  preFilledEmail = '',
  onSubmit
}: Props): ReturnProps => {
  const [userForm, setUserForm] = useState<UserForm>(
    initialUserForm(preFilledEmail)
  )
  const [errors, setErrors] = useState([] as Array<string>)
  const { currentToken } = useReCaptchaContext()
  const [, { signIn }] = useAuthContext()
  const { databaseId } = usePublisherContext()

  const updateErrors = (errors: Array<string>): void => {
    setErrors(errors)
  }

  const change = (fieldName: FieldName, value: string) => {
    setUserForm((prevUserForm) => ({
      ...prevUserForm,
      [fieldName]: {
        value,
        isValid: validations[fieldName](value)
      }
    }))
  }

  const validate = (fieldName: FieldName) => {
    setUserForm((prevUserForm) => {
      const { value } = prevUserForm[fieldName]

      return {
        ...prevUserForm,
        [fieldName]: {
          value: value.trim(),
          isValid: validations[fieldName](value)
        }
      }
    })
  }

  const handleChange =
    (name: FieldName) => (event: ChangeEvent<HTMLInputElement>) => {
      change(name, event.target.value)
    }

  const handleBlur = (name: FieldName) => () => {
    validate(name)
  }

  const handleReferrerCookie = (): void => {
    Cookies.set('referrer_to', Router.asPath)
  }

  const sendAnalytics = (user: User) => {
    checkoutAnalytics.emailLogin()
    segmentAnalytics.trackSignIn({
      email: user.email,
      userId: user.id,
      signType: 'email',
      publisherId: databaseId as number
    })
  }

  const submitUser = async (e: FormEvent): Promise<void> => {
    e.preventDefault()

    updateErrors([])

    const captchaToken = await currentToken(
      RecaptchaAction.RECAPTCHA_SIGN_IN
    ).catch((e: Error) => updateErrors([e.message]))

    if (!captchaToken) return

    try {
      const user = await signIn({
        user: {
          email: userForm.email.value,
          password: userForm.password.value
        },
        ...captchaToken
      })

      sendAnalytics(user)

      if (onSubmit) onSubmit(user)
    } catch (error) {
      updateErrors([INVALID_CREDENTIALS])
    }
  }

  return {
    handleReferrerCookie,
    handleChange,
    handleBlur,
    userForm,
    errors,
    submitUser
  }
}
