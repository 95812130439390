import { ComponentProps } from 'react'
import Badge from '@atoms/Badge/Badge'
import { DoneIcon } from '@atoms/Icon/Icon'
import { UIStyles } from '@lib/UIComponents/types'

type RequirementProps = ComponentProps<typeof Badge> & {
  condition: boolean
  sx?: UIStyles
}

const Requirement = ({
  sx,
  condition,
  children,
  ...props
}: RequirementProps) => {
  return (
    <Badge
      rounded={false}
      variant={condition ? 'green' : 'gray'}
      {...props}
      sx={{
        '& > svg': {
          mr: 2
        },
        ...sx
      }}
    >
      {condition && <DoneIcon data-testid="done-icon" />}
      {children}
    </Badge>
  )
}

export default Requirement
