import { ChangeEvent, ComponentProps } from 'react'
import Input from '@atoms/UIInput/Input'
import Requirement from '@molecules/Requirement/Requirement'
import { Flex } from '@lib/UIComponents'

type Props = {
  value: string
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
} & Omit<ComponentProps<typeof Input>, 'onChange'> &
  (
    | { validate: true; isValid: { content: boolean; length: boolean } }
    | { validate?: undefined; isValid?: undefined }
  )

const PasswordInput = ({
  value,
  validate,
  isValid,
  onChange,
  ...props
}: Props) => {
  const showFieldRequirements =
    validate &&
    isValid &&
    (isValid.content === false || isValid.length === false) &&
    Boolean(value)

  return (
    <>
      <Input type="password" value={value} onChange={onChange} {...props} />

      {showFieldRequirements && (
        <Flex
          sx={{
            flexDirection: ['column', 'column', 'row'],
            justifyContent: 'space-between',
            mt: 3
          }}
        >
          <Requirement condition={isValid.length}>
            Minimum 8 characters
          </Requirement>

          <Requirement condition={isValid.content} sx={{ mt: [3, 3, 0] }}>
            Contains letters & numbers
          </Requirement>
        </Flex>
      )}
    </>
  )
}

export default PasswordInput
