import { ChangeEvent, ComponentProps } from 'react'
import Input from '@atoms/UIInput/Input'
import Requirement from '@molecules/Requirement/Requirement'

type Props = {
  value: string
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
} & Omit<ComponentProps<typeof Input>, 'onChange'> &
  (
    | { validate: true; isValid: boolean }
    | { validate?: undefined; isValid?: undefined }
  )

const EmailInput = ({
  value,
  isValid,
  validate,
  onChange,
  ...props
}: Props) => {
  const showFieldRequirements = validate && isValid === false && Boolean(value)

  return (
    <>
      <Input
        type="email"
        autoComplete="true"
        value={value}
        onChange={onChange}
        {...props}
      />

      {showFieldRequirements && (
        <Requirement
          sx={{ mt: 3, width: ['100%', '100%', 'auto'] }}
          condition={isValid}
        >
          Must be a valid email address
        </Requirement>
      )}
    </>
  )
}

export default EmailInput
