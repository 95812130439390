import { ChangeEvent, FormEvent } from 'react'

import Button from '@atoms/UIButton/Button'
import Container from '@atoms/UIContainer/Container'
import Heading from '@atoms/UIHeading/Heading'
import Separator from '@atoms/Separator/Separator'

import useSignUp, { FieldName } from '@concepts/Auth/hooks/useSignUp'
import FacebookAuthButton from '@molecules/FacebookAuthButton/FacebookAuthButton'
import GoogleAuthButton from '@molecules/GoogleAuthButton/GoogleAuthButton'
import { redirectToForgotYourPasswordPage } from '@concepts/Auth/utils/redirect'
import ClaimAccount from '@molecules/ClaimAccount/ClaimAccount'
import EmailInput from '@atoms/EmailInput/EmailInput'
import PasswordInput from '@atoms/PasswordInput/PasswordInput'
import { Box } from '@lib/UIComponents'

type Props = {
  onSubmit?: Function
  returnTo: string
  title?: boolean
  buttonPosition?: 'top' | 'bottom'
  buttonText?: string
}

const SignUpForm = ({
  returnTo,
  onSubmit,
  title = false,
  buttonPosition = 'top',
  buttonText = 'Sign Up'
}: Props) => {
  const {
    userForm,
    isLoading,
    error,
    isClaimAccountError,
    change,
    validate,
    signUp
  } = useSignUp({ onSubmit })

  const handleChange =
    (name: FieldName) => (event: ChangeEvent<HTMLInputElement>) => {
      change(name, event.target.value)
    }

  const handleBlur = (name: FieldName) => () => {
    validate(name)
  }

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault()

    signUp()
  }

  const isDisabled =
    !userForm.email.isValid ||
    !userForm.password.isValid.length ||
    !userForm.password.isValid.content

  return (
    <Container>
      {error && (
        <ClaimAccount
          redirectToResetPasswordPage={() =>
            redirectToForgotYourPasswordPage({ shouldSetReturnTo: false })
          }
          isClaimAccountError={isClaimAccountError}
          error={error}
        />
      )}
      {title && (
        <Heading as="h2" sx={{ mb: 7, fontSize: [6, 7], textAlign: 'center' }}>
          Create An Account
        </Heading>
      )}
      <Box as="form" onSubmit={handleSubmit}>
        <EmailInput
          id="email"
          placeholder="Email"
          value={userForm.email.value}
          validate
          isValid={userForm.email.isValid}
          onChange={handleChange('email')}
          onBlur={handleBlur('email')}
          required
        />

        <PasswordInput
          id="password"
          placeholder="Password"
          value={userForm.password.value}
          validate
          isValid={userForm.password.isValid}
          onChange={handleChange('password')}
          sx={{ mt: 3 }}
          type="password"
          required
        />

        {buttonPosition === 'top' && (
          <Button
            variant="dark"
            size="large"
            loading={isLoading}
            disabled={isDisabled}
            block
            type="submit"
            sx={{ mt: 5 }}
          >
            {buttonText}
          </Button>
        )}

        <Separator text="OR" size="small" variant="light" />

        <FacebookAuthButton signIn={false} returnTo={returnTo} />
        <GoogleAuthButton signIn={false} returnTo={returnTo} />

        {buttonPosition === 'bottom' && (
          <Button
            variant="dark"
            size="large"
            loading={isLoading}
            disabled={isDisabled}
            block
            sx={{ mt: 5 }}
            type="submit"
          >
            {buttonText}
          </Button>
        )}
      </Box>
    </Container>
  )
}

export default SignUpForm
